import {
  CLEAN_DATA,
  RESULT_DATA,
  LOADING_DATA,
  GET_DATA_ERROR,
  HEADER_MENUS_DATA,
  GET_HEADER_MENUS_ERROR,
  LOADING_HEADER_MENUS,
  APPLICATION_MENUS_DATA,
  GET_APPLICATION_MENUS_ERROR,
  LOADING_APPLICATION_MENUS,
  FOOTER_MENUS_DATA,
  GET_FOOTER_MENUS_ERROR,
  LOADING_FOOTER_MENUS,
  FOOTER_SUB_MENUS_DATA,
  GET_FOOTER_SUB_MENUS_ERROR,
  LOADING_FOOTER_SUB_MENUS,
  ACCESS_MENUS_DATA,
  GET_ACCESS_MENUS_ERROR,
  LOADING_ACCESS_MENUS,
  PLANS_DATA,
  GET_PLANS_ERROR,
  LOADING_PLANS,
  PLAN_FILTERS_DATA,
  GET_PLAN_FILTERS_ERROR,
  LOADING_PLAN_FILTERS,
  SEARCH_DATA,
  FETCH_SEARCH_ERROR,
  LOADING_SEARCH,
  SEARCH_AUTOCOMPLETE_DATA,
  FETCH_SEARCH_AUTOCOMPLETE_ERROR,
  LOADING_SEARCH_AUTOCOMPLETE,
  FOOTER_DATA,
  FOOTER_DATA_ERROR,
} from 'store/constants'

export const initialState = {
  data: {},
  dataError: false,
  loading: false,
  headerMenus: {},
  headerMenusError: false,
  loadingHeaderMenus: false,
  applicationMenus: {},
  applicationMenusError: false,
  loadingApplicationMenus: false,
  footerMenus: {},
  footerMenusError: false,
  loadingFooterMenus: true,
  footerSubMenus: {},
  footerSubMenusError: false,
  loadingFooterSubMenus: true,
  accessMenus: {},
  accessMenusError: false,
  loadingAccessMenus: true,
  plans: {},
  plansError: false,
  loadingPlans: {},
  planFilters: [],
  planFiltersError: false,
  loadingPlanFilters: true,
  searchData: {},
  fetchSearchError: false,
  loadingSearch: true,
  searchAutocompleteData: {},
  fetchSearchAutocompleteError: false,
  loadingSearchAutocomplete: false,
  footerData: {},
  footerDataError: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAN_DATA:
      return { ...state, data: {} }
    case RESULT_DATA:
      return { ...state, data: action.payload }
    case GET_DATA_ERROR:
      return { ...state, dataError: action.payload }
    case LOADING_DATA:
      return { ...state, loading: action.payload }
    case HEADER_MENUS_DATA:
      return { ...state, headerMenus: action.payload }
    case GET_HEADER_MENUS_ERROR:
      return { ...state, headerMenusError: action.payload }
    case LOADING_HEADER_MENUS:
      return { ...state, loadingHeaderMenus: action.payload }
    case APPLICATION_MENUS_DATA:
      return { ...state, applicationMenus: action.payload }
    case GET_APPLICATION_MENUS_ERROR:
      return { ...state, applicationMenusError: action.payload }
    case LOADING_APPLICATION_MENUS:
      return { ...state, loadingApplicationMenus: action.payload }
    case FOOTER_MENUS_DATA:
      return { ...state, footerMenus: action.payload }
    case GET_FOOTER_MENUS_ERROR:
      return { ...state, footerMenusError: action.payload }
    case LOADING_FOOTER_MENUS:
      return { ...state, loadingFooterMenus: action.payload }
    case FOOTER_SUB_MENUS_DATA:
      return { ...state, footerSubMenus: action.payload }
    case GET_FOOTER_SUB_MENUS_ERROR:
      return { ...state, footerMenusSubError: action.payload }
    case LOADING_FOOTER_SUB_MENUS:
      return { ...state, loadingFooterSubMenus: action.payload }
    case ACCESS_MENUS_DATA:
      return { ...state, accessMenus: action.payload }
    case GET_ACCESS_MENUS_ERROR:
      return { ...state, accessMenusError: action.payload }
    case LOADING_ACCESS_MENUS:
      return { ...state, loadingAccessMenus: action.payload }
    case PLANS_DATA:
      return { ...state, plans: { ...state.plans, ...action.payload } }
    case GET_PLANS_ERROR:
      return { ...state, plansError: action.payload }
    case LOADING_PLANS:
      return { ...state, loadingPlans: { ...action.payload } }
    case PLAN_FILTERS_DATA:
      return { ...state, planFilters: action.payload }
    case GET_PLAN_FILTERS_ERROR:
      return { ...state, planFiltersError: action.payload }
    case LOADING_PLAN_FILTERS:
      return { ...state, loadingPlanFilters: action.payload }
    case SEARCH_DATA:
      return { ...state, searchData: action.payload }
    case FETCH_SEARCH_ERROR:
      return { ...state, fetchSearchError: action.payload }
    case LOADING_SEARCH:
      return { ...state, loadingSearch: action.payload }
    case SEARCH_AUTOCOMPLETE_DATA:
      return { ...state, searchAutocompleteData: action.payload }
    case FETCH_SEARCH_AUTOCOMPLETE_ERROR:
      return { ...state, fetchSearchAutocompleteError: action.payload }
    case LOADING_SEARCH_AUTOCOMPLETE:
      return { ...state, loadingSearchAutocomplete: action.payload }
    case FOOTER_DATA:
      return { ...state, footerData: action.payload }
    case FOOTER_DATA_ERROR:
      return { ...state, footerDataError: action.payload }
    default:
      return state
  }
}
