import React, { useRef, useEffect, useContext } from 'react'
import { ReactReduxContext, useSelector, useDispatch } from 'react-redux'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { get } from 'lodash'
import { getMenuName } from 'helpers/auth'
import { getData } from 'store/modules/customPage/actions'
import { isFirefox } from 'helpers/tools'

// Components
import CustomPage from 'components/views/CustomPage'
import LandingPage from 'components/views/LandingPage'
import LoginPage from 'components/views/LoginPage'

import { static_routes } from 'server/staticRoutes'

const Routes = () => {
  const { user } = useSelector(({ auth }) => auth)
  const menu_name = getMenuName(user)
  const { data } = useSelector(({ customPage }) => customPage)
  const { store } = useContext(ReactReduxContext)
  const dispatch = useDispatch()
  const { pathname, search, key } = useLocation()
  const headerRef = useRef(null)
  const contentRef = useRef(null)
  const footerRef = useRef(null)
  const navRef = useRef(null)
  const refs = {
    header: headerRef,
    content: contentRef,
    footer: footerRef,
    nav: navRef,
  }

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      // Content
      if (isFirefox() ? e.shiftKey && e.altKey && e.keyCode === 49 : e.altKey && e.keyCode === 49) {
        refs.content.current.scrollIntoView()
        refs.content.current.focus()
      }
      // Menu
      if (isFirefox() ? e.shiftKey && e.altKey && e.keyCode === 50 : e.altKey && e.keyCode === 50) {
        refs.nav.current.scrollIntoView()
        refs.nav.current.focus()
      }
      // Footer
      if (isFirefox() ? e.shiftKey && e.altKey && e.keyCode === 52 : e.altKey && e.keyCode === 52) {
        refs.footer.current.scrollIntoView()
        refs.footer.current.focus()
      }
    })
  })

  const dispatchRoutes = () => {
    const isStaticRoute = static_routes.includes(pathname.split('/')[1])
    
    if (!window.__SERVER_SIDE__ && !isStaticRoute) {
      dispatch(
        getData({
          pathname,
          search,
          menu_name: menu_name,
          store: store,
        })
      )
    }
  }

  useEffect(() => {
    dispatchRoutes()
    window.__SERVER_SIDE__ = false
  }, [pathname, search, key])

  const customRoute = () => {
    const view = get(data, 'route.entity.view')

    switch (view) {
      case 'landing_page':
        return (
          <Route path="/">
            <LandingPage user={user} refs={refs} />
          </Route>
        )
      default:
        return (
          <Route path="/">
            <CustomPage user={user} refs={refs} />
          </Route>
        )
    }
  }

  return (
    <Switch>
      <Route exact path="/home">
        <Redirect to="/" user={user} />
      </Route>
      <Route path="/login">
        <LoginPage user={user} key="login" refs={refs} />
      </Route>
      <Route path="/email-validated/:userId/:userToken">
        <LoginPage step="LoginEmailValidated" key="emailValidation" user={user} refs={refs} />
      </Route>
      <Route path="/reset-password/:userId/:userToken">
        <LoginPage step="ChangePassword" user={user} refs={refs} />
      </Route>
      <Route path="/activate-profile/:userId/:userToken">
        <LoginPage step="ActivateProfile" user={user} refs={refs} />
      </Route>
      <Route path="/accept-invitation/:userId/:userToken">
        <LoginPage step="ChangePassword" fromStep="AcceptInvitation" user={user} refs={refs} />
      </Route>
      <Route path="/simular-acesso">
        <LoginPage step="AccessSimulation" key="accessSimulation" user={user} refs={refs} />
      </Route>
      <Route path="/change-profile">
        <LoginPage step="ChangeProfile" key="changeProfile" user={user} refs={refs} />
      </Route>
      <Route path="/cadastre-se">
        <LoginPage step="LoginRegister" key="cadastro" user={user} refs={refs} />
      </Route>
      <Route path="/lp">
        <LandingPage user={user} refs={refs} />
      </Route>
      <Route path="/" render={customRoute} />
      <Route path="*">Not found</Route>
    </Switch>
  )
}

export default Routes
