import React, { useState, useEffect, useRef, useContext } from 'react'
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux'
import ReCaptcha from 'react-google-recaptcha'
import { useHistory } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { Form, Button, Spinner } from 'react-bootstrap'
import Helmet from 'react-helmet'
import * as authActions from 'store/modules/auth/actions'
import * as tokenActions from 'store/modules/token/actions'
import * as customPageActions from 'store/modules/customPage/actions'
import { get, isEqual } from 'lodash'
import Typography from 'components/atoms/Typography'
import SVG from 'react-inlinesvg'
import IconGoogleColor from 'assets/img/icon-google-color.svg'
// import IconFacebook from 'assets/img/icon-facebook.svg'
import CustomModal from 'components/molecules/CustomModal'
import { getMenuName, invalidateUser } from 'helpers/auth'
import { profileNamesForLoggedArea, isClient, isDebugMode, checkIfIPIsInWhitelist } from 'helpers/tools'
import CustomButton from 'components/atoms/CustomButton'

// Styles
import loginStyles from './LoginForm.scss'

function LoginForm(props) {
  const recaptchaRef = useRef()
  const { store } = useContext(ReactReduxContext)
  const dispatch = useDispatch()
  const history = useHistory()
  const changeStep = get(props, 'changeStep')
  const changeHeaderVisibility = get(props, 'changeHeaderVisibility')
  const changeBreadcrumbVisibility = get(props, 'changeBreadcrumbVisibility')
  const token = useSelector((state) => state.token.token, isEqual)
  const ip = useSelector((state) => state.auth.ip, isEqual)
  const token_error = useSelector((state) => state.token.tokenError)
  const token_loading = useSelector((state) => state.token.loadingToken)
  const login_user = useSelector((state) => state.auth.user)
  const login_user_error = useSelector((state) => state.auth.userError)
  const login_user_loading = useSelector((state) => state.auth.loadingUser)

  const [formValidated, setFormValidated] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [hasCaptcha, setHasCaptcha] = useState(true)
  const [captcha, setCaptcha] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [authenticate, setAuthenticate] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const whitelist = process.env.RAZZLE_IP_WHITELIST

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() && captcha) {
      const payload = {
        username: username,
        password: password,
        'g-recaptcha-response': captcha,
      }
      setAuthenticate(true)
      dispatch(tokenActions.getToken(payload))
    }
    setFormValidated(true)
    dispatch(customPageActions.cleanData())
  }

  useEffect(() => {
    if (authenticate && get(token, 'access_token')) {
      setAuthenticate(false)
      setRedirect(true)
      dispatch(authActions.getUser({ default: true, store: store }))
    }
  }, [token])

  useEffect(() => {
    if (!ip) {
      dispatch(authActions.getIpAddress())
    } else {
      const { host = '', search = '' } = window?.location || {}
      const isNotDebug = !isDebugMode({ host, search })
      const isNotIPInWhitelist = !checkIfIPIsInWhitelist(ip, whitelist)
      const shouldShowCaptcha = isNotDebug && isNotIPInWhitelist
      setHasCaptcha(shouldShowCaptcha)
    }
  }, [ip])

  useEffect(() => {
    if (!hasCaptcha) {
      setCaptcha('NOCAPTCHA')
    }
  }, [hasCaptcha])

  useEffect(() => {
    if (login_user?.menu) {
      const menuName = getMenuName(login_user)
      dispatch(customPageActions.getApplicationMenus({ menu: menuName, store: store }))
    }
    if (redirect) {
      /* dispatch(customPageActions.getApplicationMenus(null)) */

      // Clear old reports
      localStorage.removeItem('reportsBeingGeneratedCopan')
      localStorage.removeItem('reportsBeingGenerated')

      const pathFromQuery = new URLSearchParams(history.location.search).get('path')
      if (pathFromQuery) {
        history.push(pathFromQuery)
      } else {
        const profileNameLoggedArea = profileNamesForLoggedArea(login_user?.menu)
        if (profileNameLoggedArea) {
          const loggedAreaPathURL = `/app/home-${profileNameLoggedArea}`
          history.push(loggedAreaPathURL)
        } else {
          history.push('/app')
        }
      }

      setRedirect(false)
    } else {
      if (login_user && history?.location?.pathname === '/login') {
        const profileNameLoggedArea = profileNamesForLoggedArea(login_user?.menu)
        if (profileNameLoggedArea) {
          const loggedAreaPathURL = `/app/home-${profileNameLoggedArea}`
          history.push(loggedAreaPathURL)
        } else {
          history.push('/app')
        }
      }
    }
  }, [login_user])

  useEffect(async () => {
    if (token_error || login_user_error) {
      setModalOpened(true)
      await invalidateUser(store)

      if (hasCaptcha) {
        recaptchaRef.current.reset()
        setCaptcha(false)
      }
      setFormValidated(false)

      const dispatchError = token_error || login_user_error
      switch (dispatchError?.message) {
        case 'The user credentials were incorrect.':
          setErrorMessage({
            text: 'Por favor, verifique seu e-mail e/ou senha e tente novamente',
            title: 'Dados incorretos.',
          })
          break

        case '403 Acesso Negado':
          setErrorMessage({
            text: 'Por favor, tente novamente mais tarde.',
            title: 'Sistema temporariamente indisponível.',
          })
          break

        case 'An API error occurred':
          setErrorMessage({
            text: 'Não foi possível estabelecer conexão com o servidor.',
            title: 'Sem conexão com o servidor',
          })
          break

        case '1010 - Não foi possível conectar ao banco de dados.':
          setErrorMessage({
            text: 'Não foi possível estabelecer conexão com o servidor.',
            title: 'Sem conexão com o servidor',
          })
          break

        default:
          setErrorMessage({ text: 'Ocorreu um erro inesperado.', title: 'Erro' })
      }
    }
  }, [token_error, login_user_error])

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const emailValidation = (email) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    return !email ? false : regex.test(email) === false
  }

  const goToRegister = () => {
    changeHeaderVisibility()
    changeBreadcrumbVisibility()
    history.push('/cadastre-se')
  }

  return (
    <>
      <Helmet>
        <style type="text/css">{loginStyles.toString()}</style>
      </Helmet>
      <CustomModal
        open={modalOpened}
        alignCenter
        closeModal={() => setModalOpened(false)}
        size="sm"
        title={errorMessage.title}
        footer={{ closeButton: true }}
      >
        <div className="ModalLogin__modal-container">
          <strong>{errorMessage.text}</strong>
        </div>
      </CustomModal>
      <section className="login-form">
        <div className="login-header">
          <div className="container">
            <Typography variant="p" cssClass="title">
              Bem-vindo à sua Unimed
            </Typography>
          </div>
        </div>
        <div className="login-footer">
          <div className="container text-center d-flex justify-content-center flex-column my-3">
            <Typography variant="p">É seu primeiro acesso ao novo portal Unimed Curitiba?</Typography>
            <CustomButton
              text="Faça seu cadastro"
              className="d-flex justify-content-center my-3"
              noIcon
              onClick={(e) => {
                e.preventDefault()
                goToRegister()
              }}
            />
            <div className="faq my-3">
              <p>
                Dúvidas sobre o perfil? <HashLink to="/duvidas-sobre-o-perfil">Clique aqui</HashLink> e entenda todos os
                perfis de acesso no portal.
              </p>
            </div>
          </div>

          <div className="divider">
            <span className="line left"></span>
            <p>ou</p>
            <span className="line right"></span>
          </div>
        </div>
        <div className="login-form-content mb-3">
          <div className="container text-center d-flex justify-content-center flex-column mb-4 mt-2">
            <Typography variant="p">Acesse com seu email e senha</Typography>
          </div>
          <div className="container">
            <Form className="login-form-area" noValidate validated={formValidated} onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>E-mail:</Form.Label>
                <Form.Control
                  type="email"
                  id="email"
                  name="email"
                  aria-label="email"
                  isInvalid={emailValidation(username)}
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Informe um e-mail válido.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="password-input">
                <Form.Label>Senha:</Form.Label>
                <span className="password-visible" onClick={togglePasswordVisiblity}>
                  {passwordShown ? <i className={'icone-eye show'} /> : <i className={'icone-eye'} />}
                </span>
                <Form.Control
                  type={passwordShown ? 'text' : 'password'}
                  id="password"
                  name="password"
                  aria-label="password"
                  required
                  value={password || ''}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Informe sua senha.</Form.Control.Feedback>
              </Form.Group>
              {hasCaptcha && (
                <Form.Group>
                  <div className="d-flex justify-content-center">
                    <ReCaptcha
                      hl="pt-BR"
                      ref={recaptchaRef}
                      size={isClient() && window.innerWidth <= 320 ? 'compact' : 'normal'}
                      sitekey={process.env.RAZZLE_RECAPTCHA_KEY}
                      onChange={setCaptcha}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <Form.Control isInvalid={formValidated && !captcha} required type="hidden" />
                    <Form.Control.Feedback className="w-auto" type="invalid">
                      Preencha o reCAPTCHA
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              )}

              <Button
                type="submit"
                className="submit-button"
                variant="primary"
                size="lg"
                block
                disabled={login_user_loading || token_loading}
              >
                {login_user_loading || token_loading ? <Spinner animation="border" size="sm" /> : 'Entrar'}
              </Button>
            </Form>
            <div className="forgot">
              <button onClick={() => changeStep('ForgotAccess')}>Esqueceu seu e-mail ou senha?</button>
            </div>
          </div>
        </div>
        <div className="login-footer">
          <div className="container">
            <section className="social-login">
              <a className="login google" href="/user/login/google">
                <SVG
                  className="icon"
                  src={IconGoogleColor}
                  preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
                  width="100%"
                  height="100%"
                />
                <p className="text">Continuar com o Google</p>
              </a>
              {/* <a href="/user/login/facebook" className="login facebook">
                <SVG
                  className="icon"
                  src={IconFacebook}
                  preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
                  width="100%"
                  height="100%"
                />
                <p className="text">Continuar com o Facebook</p>
              </a> */}
            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default LoginForm
